import http from "../services/http";

export async function getProperties(params) {
    console.log("Params", params);

    try {
        const response = await http.get(`/properties?${params}`);
        console.log("Response", response.data);
        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getHighlightProperties() {
    try {
        const response = await http.get(`/properties/highlights`);
        console.log("Response", response.data);
        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getProperty(id) {
    try {
        const response = await http.get(`/properties/${id}`);
        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function getLocals(query) {
    try {
        const response = await http.get(`/properties/locals?keyword=${query}`);
        return response.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}